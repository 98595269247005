<template>
  <div class="lsg-page lsg-trade__page trade_page">
    <div class="lsg-trade" v-if="property">
      <div class="main__header">
        <div class="main__header__item left">
          <h1>{{ getName(property.contract, property) }}</h1>
        </div>
      </div>
      <div class="main__content">
        <div class="crm">
          <aside class="crm__aside" style="width: 500px">
            <div v-if="property.contract" class="workspace_left__header" style="padding: 20px">
              <div class="workspace_left__row">
                <div class="workspace_left__item">
                  <span>Договор</span>
                </div>
                <div class="workspace_left__item">
                  <span>{{ property.contract.name || 'Нет данных' }}</span>
                </div>
              </div>
              <div v-if="property.contract.property_vin" class="workspace_left__row">
                <div class="workspace_left__item">
                  <span>VIN</span>
                </div>
                <div class="workspace_left__item">
                  <span>{{ property.contract.property_vin || 'Нет данных' }}</span>
                </div>
              </div>
              <!-- <div v-if="property.contract.property_sign" class="workspace_left__row">
                <div class="workspace_left__item">
                  <span>Подпись</span>
                </div>
                <div class="workspace_left__item">
                  <span>{{ property.contract.property_sign || 'Нет данных' }}</span>
                </div>
              </div> -->
            </div>
            <div v-if="property && property.info" style="padding: 20px" class="workspace_left__header">
              <div v-if="!property.contract" class="workspace_left__row">
                <div class="workspace_left__item">
                  <span>Договор</span>
                </div>
                <div class="workspace_left__item">
                  <span>{{ parseContract(property.title) || 'Нет данных' }}</span>
                </div>
              </div>
              <div
                class="workspace_left__row"
                v-for="(elem, index) in objToArrayObj(property.info)"
                :key="index"
              >
                <template v-if="isLink(elem.value)">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <a :href="elem.value" class="link" target="_blank">{{ elem.title }}</a>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>{{ elem.title }}</span>
                    </div>
                  </div>
                  <div class="workspace_left__item">
                    <span>{{ elem.value }}</span>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="property.docs && property.docs.length" class="workspace_left__header" style="padding: 20px; border: none">
              <div class="workspace_left__row" style="align-items: flex-start">
                <div class="workspace_left__item">
                  <div class="workspace_left__budget">
                    <strong>Документы</strong>
                  </div>
                </div>
              </div>
              <div class="workspace_left__row" style="align-items: flex-start; margin-top: 10px">
                <div
                  class="workspace_left__item"
                  style="display: flex; flex-direction: column; align-items: flex-start"
                >
                  <a
                    v-for="doc in property.docs"
                    :key="doc"
                    download
                    :href="`${baseURL}/api/seized-property/file?dir=${property.title}&filename=${doc}`" target="_blank"
                    style="text-align: start"
                  >
                    {{ doc }}
                  </a>
                </div>
              </div>
            </div>
            <div class="workspace_left__row" style="padding: 20px">
              <div class="workspace_left__item">
                <a
                  class="btn"
                  :href="`${baseURL}/api/seized-property/archive?hash=${$route.params.hash}`"
                  download
                >
                  Скачать архивом
                </a>
              </div>
            </div>
            <div v-if="property && property.info && property.info['Авито'] && /https/.test(property.info['Авито'])" class="workspace_left__row" style="padding: 20px">
              <div class="workspace_left__item">
                <button class="btn" @click="copyAvitoLink(property.info['Авито'])">
                  Поделиться
                </button>
              </div>
            </div>
          </aside>
          <div class="images-container">
            <img
              class="images-container__image sceleton"
              v-for="(image, index) in property.images"
              :key="image"
              :src="`${baseURL}/api/seized-property/image?dir=${property.title}&filename=${image}`"
              :alt="image"
              loading="lazy"
              @click="handleImage(index)"
              @load="handleImageLoad"
            />
          </div>
        </div>
      </div>
    </div>
    <FullScreenImageModal
      v-if="isOpenImage"
      :imageLink="link"
      :imageAlt="alt"
      @close="closeModal"
      @backImage="goBackImage"
      @nextImage="goNextImage"
    />
  </div>
</template>

<script>
import axios from 'axios'
import FullScreenImageModal from '@/components/layouts/modals/components/FullScreenImageModal.vue'

import { copyText } from '@/helpers/utils'

export default {
  name: 'SeizedPropertiesSelected',
  data () {
    return {
      baseURL: process.env.VUE_APP_HTTP,
      property: null,
      isOpenImage: false,
      link: null,
      alt: null,
      imageIndex: null
    }
  },
  components: {
    FullScreenImageModal
  },
  methods: {
    copyAvitoLink (link) {
      return copyText(this, link, 'Ссылка на Авито скопирована в буфер обмена')
    },
    isLink (text) {
      return /^(http:\/\/|https:\/\/)/.test(text)
    },
    checkIndex (index, length) {
      if (index > (length - 1)) return 0
      else if (index < 0) return (length - 1)
      else return index
    },
    handleImage (index) {
      this.imageIndex = index
      this.openImage(`${this.baseURL}/api/seized-property/image?dir=${this.property.title}&filename=${this.property.images[this.imageIndex]}`)
    },
    goNextImage () {
      this.handleImage(this.checkIndex(this.imageIndex + 1, this.property.images.length))
    },
    goBackImage () {
      this.handleImage(this.checkIndex(this.imageIndex - 1, this.property.images.length))
    },
    openImage (link, alt) {
      if (link) {
        this.link = link
        if (alt) this.alt = alt
        this.isOpenImage = true
      }
    },
    closeModal () {
      this.link = null
      this.alt = null
      this.isOpenImage = false
    },
    getName (contract, property) {
      if (contract && (contract.name || contract.property_name)) return contract.property_name || contract.name
      else if (property && property.info && property.info['Наименование']) return property.info['Наименование']
      else return 'Без наименования'
    },
    parseContract (str) {
      const regexp = /\[(.*?)\]/
      const matches = str.match(regexp)

      if (matches && matches.length > 1) {
        return matches[1]
      }
    },
    objToArrayObj (obj) {
      const keys = Object.keys(obj)
      const result = keys.map(key => {
        return {
          title: key,
          value: obj[key]
        }
      })
      return result
    },
    handleImageLoad (event) {
      const image = event.target
      image.classList.remove('sceleton')
    }
  },
  async created () {
    const hash = this.$route.params.hash
    const response = await axios.get(`${this.baseURL}/api/seized-property/dir?hash=${hash}`)
    this.property = response.data
  }
}
</script>

<style lang="stylus" scoped>
.images-container
  width 100%

  display grid
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
  grid-gap 16px

  padding 15px

  &__image
    height 100%
    min-width 100%
    max-width 100%
    object-fit cover
    object-position center
    cursor pointer

@media only screen and (max-width: 768px)
  .crm
    width: 100%

@keyframes loading
  0%
    background-position: 100% 50%
  100%
    background-position: 0 50%

.sceleton
  position: relative
  height: auto
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%)
  background-size: 400%
  animation: loading 1.2s ease-in-out infinite

</style>
